<template>
    <div class="comp">
        <div class="comp-container">
            <h2> پیام ها </h2>
            <div  class="notifications">
                <template v-if="notifs.length > 0">
                <div v-for="(item,index) in notifs" :key="index" class="notification">
                    <div class="title">
                        <span> {{item.title}} </span>
                        <span> {{$G2J(item.createdAtDateTime)}} </span>
                    </div>
                    <p> {{item.content}} </p>
                </div>
                </template>
                <template v-else>
                        <div class="no-content">
                            <span> محتوایی جهت نمایش وجود ندارد </span>
                        </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Notifications',
        data() {
            return {
                    notifs:[],
                    allTotalElements:0,
                    page:1,
                    perPage:10,
            }
        },
        methods: {
            async getMessages(page = this.page, perPage = this.perPage) {
                this.state.loading = true
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                             page: page, size: perPage,
                        }
                    })
                if (res) {
                    this.notifs.push(...res.content)
                    this.allTotalElements = res.totalElements
                }
            },
        },
        mounted() {
            this.getMessages();
        },
    }
</script>

<style lang="scss" scoped>
.no-content{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    // padding-top: 30px;
    span{
        font-size: clamp(14px,1vw,16px);
        font-weight: 400;
        color: var(--grey);   
    }
}
h2{
    font-size: clamp(16px,2vw,20px);
}
.comp-container{
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
    justify-content: start;
}
.comp{
    min-height: 92vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.notifications{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    padding: 24px;
    row-gap: 24px;
}
.notification{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border-bottom: 1px solid var(--light-grey);
    color: var(--grey);
    .title{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
@media only screen and(max-width:1000px){
    .title{
        flex-direction: column !important;
        row-gap: 20px !important;
    }
}
</style>